import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrl: './join.component.scss'
})
export class JoinComponent {
  joinRoomForm: FormGroup;

  constructor(private router: Router, private userSer: UserService, private formBuilder: FormBuilder) {
   
    this.joinRoomForm = this.formBuilder.group({
      name: ['', Validators.required],
      code: ['', Validators.required]
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url.includes('join?code=')) {
        let code = event.url.split('code=')[1];
        this.joinRoomForm.controls['code'].setValue(code);
      }
    });
  }
  joinAsGuest() {
    console.log(this.joinRoomForm.value);
    sessionStorage.setItem('username', this.joinRoomForm.value.name);
    sessionStorage.setItem('code', this.joinRoomForm.value.code);
    this.router.navigate(['/planning']);
  }
}
