import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { PlanningComponent } from './planning-poker/components/planning/planning.component';
import { LoginComponent } from './components/login/login.component';
import { PlanningPokerModule } from './planning-poker/planning-poker.module';
import { JoinComponent } from './components/join/join.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'join',
    component: JoinComponent
  },
  { path: 'landing', component: LandingComponent },
  {
    path: 'planning',
    loadChildren: () => import('./planning-poker/planning-poker.module').then(m => PlanningPokerModule)
  },
  { path: '**', component: LandingComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }