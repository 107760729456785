import { Component } from '@angular/core';
import { PlanningPokerService } from '../../services/planning-poker.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  showInvite!: boolean;
  userName: string = '';
  roomTitle: string = '';
  currentRoom: any;
  constructor(private planningSer: PlanningPokerService, 
    private userService: UserService,
    private router: Router) {
    this.userName = sessionStorage.getItem('username') || '';
    this.userService.checkForActiveRoom().subscribe((res: any) => {
      if(res.roomExist) {
        this.userService.setCurrentRoom(res.room);
      }
    });
    this.userService.getCurrentRoom().subscribe((room: any) => {
      if(room) {
        this.currentRoom = room;
        this.showInvite = true;
      }
    })
  }
  createRoom() {
    this.planningSer.createRoom(this.roomTitle).subscribe((res: any) => {
      if(res) {
        this.showInvite = true;
        this.roomTitle = '';
        sessionStorage.setItem('code', res.code);
        this.userService.setCurrentRoom(res);
      }
    })
  }
  joinRoom() {
    sessionStorage.setItem('code', this.currentRoom.code);
    this.router.navigate(['/planning']);
  }

  endRoom() {
    this.planningSer.endRoom(this.currentRoom.id).subscribe((res: any) => {
      if(res) {
        this.showInvite = false;
        this.userService.setCurrentRoom(null);
      }
    })
  }



    // const headers = new HttpHeaders().set('Authorization',  `Bearer ${sessionStorage.getItem('token')}`);
    // this.planningSer.createRoom(title, headers).subscribe((res: any) => {
    //   if(res) {
    //     this.showInvite = true;
    //     sessionStorage.setItem('code', res.code);
    //   //   {
    //   //     "title": "mettingg",
    //   //     "moderatorID": 2,
    //   //     "gameCount": 1,
    //   //     "code": "dY6PedWk",
    //   //     "isActive": true,
    //   //     "id": 3,
    //   //     "created_at": "2024-02-25T15:01:38.000Z",
    //   //     "updated_at": "2024-02-25T15:01:38.000Z"
    //   // }
    //   }
    // });
  }
