import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LandingComponent } from './components/landing/landing.component';
import { HeaderComponent } from './shared/header/header.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PlanningPokerService } from './services/planning-poker.service';
import { TokenInterceptorService } from './services/interceptor/token.interceptor';
import { AppRoutingModule } from './app-routing-module';
import { LoginComponent } from './components/login/login.component';
import { JoinComponent } from './components/join/join.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        LoginComponent,
        JoinComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        ClipboardModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule
    ],
    providers: [
        PlanningPokerService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptorService,
          multi: true
        }
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }