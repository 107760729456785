<div class="col-6">
    <div class="card" style="width: 70%">
        <div class="card-header">
            <h3>Join a Room (Guest)</h3>
        </div>
        <div class="card-body" [formGroup]="joinRoomForm">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" formControlName="name" placeholder="Enter Your Name">
                </div>
                <div class="form-group mt-3">
                    <label for="room">Room Code</label>
                    <input type="text" class="form-control" formControlName="code" placeholder="Enter Room Code">
                </div>
                <div class="form-group mt-4">
                    <button class="btn btn-primary" [disabled]="!joinRoomForm.valid" (click)="joinAsGuest()">
                        Join As Guest
                    </button>
                </div>
        </div>
    </div>
</div>