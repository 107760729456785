<div class="row">
    <!-- <div class="col-6 text-center">
        <div class="img-container">
            <img src="assets/planning.png" alt="logo">
        </div>
       
        <div>
            <input type="text" (keyup.enter)="connect()" [(ngModel)]="name" placeholder="Enter Your Name">
            <button class="btn btn-primary" (click)="connect()">Enter</button>
        </div>
    </div> -->

    <div class="col-12">
        <div class="card" style="width: 70%">
            <div class="card-header">
                <h3>Login</h3>
            </div>
            <div class="card-body" [formGroup]="loginForm">
                    <div class="form-group">
                        <label for="name">Email</label>
                        <input type="text" class="form-control" formControlName="email" placeholder="Enter Your Name">
                    </div>
                    <div class="form-group mt-3">
                        <label for="room">Password</label>
                        <input type="password" class="form-control"  formControlName="password" placeholder="Enter Room Code">
                    </div>
                    <!-- <div class="form-group mt-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="rememberMe">
                            <label class="form-check-label" for="rememberMe">
                                Remember me
                            </label>
                        </div>
                    </div> -->
                    <button class="btn btn-primary mt-3" (click)="login()">
                        Login
                    </button>
            </div>
        </div>
    </div>
</div>