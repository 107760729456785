import { Component } from '@angular/core';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(private userService: UserService, private router: Router) {
    let token = sessionStorage.getItem('token');
    if(token) {
      this.userService.validateToken().subscribe();
      //create a method to check any available games for this user are active
      //if there are active games, navigate to the game room
      //else navigate to the landing page
      this.userService.checkForActiveRoom().subscribe((res: any) => {
        if(res.roomExist) {
          this.userService.setCurrentRoom(res.room);
        }
      });

    } else {
      //wrong place
      // this.router.navigate(['/login']);
    }
  }
  
}
