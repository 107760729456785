<app-header [showInvite]="showInvite"></app-header>
<div class="landing-container text-center">
    <div class="landing-content" *ngIf="!showInvite">
        <div class="landing-title">
            <div class="user">Welcome <b>{{userName}}</b> </div>
            <p class="mt-4">Happy Estimating, Create a room and send it your colleagues</p>
        </div>
        <div class="landing-buttons">
            <button class="btn btn-primary btn-sm me-3" data-bs-toggle="modal" data-bs-target="#createRoomModal">Create Room</button>
            <button class="btn btn-secondary btn-sm" disabled>Join Room</button>
        </div> 
    </div>
    <div class="room-content" *ngIf="showInvite">
        <div class="landing-title">
            <div class="user">Welcome back <b>{{userName}}</b> </div>
            <p class="mt-4">Seems you have an active Room, Click 
                <button class="btn btn-primary btn-sm me-2 ms-2" (click)="joinRoom()">Join Room</button>
                to enter the ROOM</p>
            <p>If you wish to create a new room, click <button class="btn btn-danger btn-sm ms-2" data-bs-toggle="modal" data-bs-target="#endRoomModal">End Room</button></p>
        </div>
    </div>
</div>


<!-- Bootstrap 5 Modal -->
<div class="modal" tabindex="-1" id="createRoomModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Create Room</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <input type="text" [(ngModel)]="roomTitle" class="form-control" placeholder="Enter Title">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" 
                (click)="createRoom()" data-bs-dismiss="modal"
                [disabled]="!(roomTitle.length > 5)">Create</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" tabindex="-1" id="endRoomModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">End Room</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>
                    Are you sure, you want to end the existing room?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                <button type="button" class="btn btn-danger" (click)="endRoom()" data-bs-dismiss="modal">End</button>
            </div>
        </div>
    </div>
</div>