import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  loginForm: FormGroup;

  constructor(private router: Router, private userSer: UserService, private formBuilder: FormBuilder) {

    this.loginForm = this.formBuilder.group({
      email: '',
      password: ''
    });
  }

  login() {
    this.userSer.login(this.loginForm.value).subscribe((res: any) => {
      if (res) {
        sessionStorage.setItem('token', res.accessToken);
        this.userSer.decodeTokenAndStoreInSessionStorage(res.accessToken);
        // if (rememberMe) {
        //   localStorage.setItem('rememberMe', 'true'); // Store the token in localStorage if rememberMe is checked
        // }
        this.router.navigate(['/landing']);
      }
    });
  }

  joinRoom() {}

  connect() {
    // sessionStorage.setItem('name', this.name);
    // this.router.navigate(['/planning']);
    // console.log('name', this.name)
  }
}
