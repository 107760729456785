import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserService } from '../user.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private userSer: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let tokenizedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.getToken()}`
      }
    });

    return next.handle(tokenizedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Logout the user
          this.logoutUser();
        }
        return throwError(error);
      })
    );
  }
  logoutUser() {
    this.userSer.logout();
  }

  getToken() {
    return sessionStorage.getItem('token');
  }
}